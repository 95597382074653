.App-member {
  display: inline-block;
  width: 100%;
  position: relative;
  margin: auto;
  overflow: hidden;
}

.App-member img {
  position: absolute;
  border-radius: 2px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 99% !important;
}

span.App-member-caption {
  display: block;
  border-radius: 2px;
  background: rgba(0,0,0,0.5);
  cursor: pointer;
  height: 100.5%;
  line-height: 2em;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100.5%;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  transition: all 300ms;
}

span.App-member-caption span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;
  color: white;
  font-size: 2.2em;
  font-style: bold;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  transition: all 300ms;
}

span.App-member-caption:not(.no-hover):hover {
  opacity: 1;
}

span.App-member-caption span:not(.no-hover):hover {
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  transform: scale(0.9);
}

.App-member-caption small {
  font-size: 0.5em;
}

@media (min-width: 576px) {
  .App-member {
    width: 50%;
  }

  .App-member img {
    width: calc(100% - 3px);
  }
}

@media (min-width: 768px) {
  .App-member {
    width: 25%;
  }

  span.App-member-caption > span {
    font-size: 2em;
  }
}
