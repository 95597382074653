.App-modal {
  z-index: 3;
  position: fixed;
  overflow-y: auto;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.4s, opacity 0.4s 0s, transform 0.4s;
}

.App-modal-content {
  margin: 1.75rem auto;
  width: 90%;
  max-width: 900px;
  padding: 10px;
  background-color: #151a22;
  border-radius: 3px;
}

.close-button {
  float: right;
  width: 1rem;
  padding: 0 2px 2px 1px;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #333;
}

.close-button:hover {
  background-color: #333;
}

.show-modal {
  opacity: 1;
  visibility: visible;
  transform: scale(1.0);
  transition: visibility 0s linear 0s, opacity 0.4s 0s, transform 0.4s;
}

.App-member-panel {
  font-weight: 300;
  padding: 18px;
}

.App-markdown-container {
  margin-top: 25px;
}

.App-member-panel h1,
.App-member-panel h3 {
  width: auto;
  margin: 5px 0;
  font-weight: 300;
}

.App-markdown-container strong {
  color: #56CCF2;
}

.member-panel-img-frame {
  float: right;
  width: 100%;
  position: relative;
  padding-top: 100%;
  margin: 0 0 15px;
  border-radius: 50%;
  overflow: hidden;
}

.member-panel-img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
}

@media (min-width: 576px) {
  .member-panel-img-frame {
    width: 50%;
    padding-top: 50%;
    margin: 6px 15px;
  }
}

@media (min-width: 768px) {
  .member-panel-img-frame {
    width: 40%;
    padding-top: 40%;
  }
}
